<template>
  <div class="vap-page">
    <Loading
      :active.sync="isLoading"
      :is-full-page="false"
      loader="dots"
      :color="loaderColor"
      :background-color="loaderBackgroundColor"
    />
    <div class="vap-page-header">
      <h1>Email Settings</h1>
      <div class="btns-container">
        <VasionButton
          id="btnSave"
          class="last-btn"
          classProp="primary"
          @vasionButtonClicked="saveConfig"
        >
          Save
        </VasionButton>
      </div>
    </div>
    <div class="vap-page-content">
      <div>
        <div>
          <div class="bottom-buffer">
            <VasionInput
              id="send-email-from"
              v-model="sendEmailFrom"
              class="input-style"
              inputType="top-white"
              name="sendEmailFrom"
              :title="'Send Email From'"
            />
          </div>
          <div class="vasion-flex">
            <div class="right-buffer bottom-buffer medium-input">
              <VasionInput
                id="smtp-server"
                v-model="smtpServer"
                class="input-style"
                inputType="top-white"
                name="smtpServer"
                :title="'SMTP Server'"
              />
            </div>
            <div class="bottom-buffer medium-input">
              <VasionInput
                id="smtp-port"
                v-model="smtpPort"
                class="input-style"
                inputType="top-white"
                name="smtpPort"
                :title="'SMTP Port'"
              />
            </div>
          </div>
          <div class="vasion-flex">
            <div class="right-buffer bottom-buffer medium-input">
              <VasionInput
                id="smtp-username"
                v-model="smtpUsername"
                class="input-style"
                inputType="top-white"
                name="smtpUsername"
                :title="'SMTP Username'"
              />
            </div>
            <div class="bottom-buffer medium-input">
              <VasionInput
                id="smtp-password"
                v-model="smtpPassword"
                class="input-style"
                inputType="top-white"
                name="smtpPassword"
                specialType="password"
                :title="'SMTP Password'"
                :canToggleShowPassword="false"
                @blur="passwordInputOnBlur"
                @focus="passwordInputOnFocus"
              />
            </div>
          </div>
          <div class="bottom-buffer-large">
            <VasionCheckbox
              id="smtpEnableSSL"
              name="smtpEnableSSL"
              :checked="smtpEnableSSL"
              @change="smtpEnableSSL = !smtpEnableSSL"
            >
              Enable SSL
            </VasionCheckbox>
          </div>
          <div class="bottom-buffer">
            <VasionInput
              id="max-merge-size"
              v-model="maxMergeSize"
              class="input-style"
              inputType="top-white"
              name="maxMergeSize"
              specialType="number"
              :title="'Max Merge and Mail Size (in bytes)'"
              :canToggleShowPassword="true"
            />
          </div>
          <div class="vasion-flex">
            <div class="bottom-buffer medium-input">
              <VasionCheckbox
                id="prompt-to-burn-annotations"
                name="promptToBurnAnnotations"
                :checked="promptToBurnAnnotations"
                @change="promptToBurnAnnotations = !promptToBurnAnnotations"
              >
                Prompt to Burn Annotations
              </VasionCheckbox>
            </div>
            <div class="bottom-buffer medium-input">
              <VasionCheckbox
                id="prompt-for-pdf-password"
                name="promptForPDFPassword"
                :checked="promptForPDFPassword"
                @change="promptForPDFPassword = !promptForPDFPassword"
              >
                Prompt for PDF Password
              </VasionCheckbox>
            </div>
          </div>
          <div class="bottom-buffer">
            <VasionDropList
              id="logoff-user-request-template"
              v-slot="slotItem"
              v-model="logoffUserRequestTemplate"
              title="Logoff User Request Template"
              name="logoffUserRequestTemplate"
              type="plain-list"
              :dataArray="templates"
              valueName="value"
              displayName="name"
              width="100%"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
          </div>
          <div class="bottom-buffer">
            <VasionDropList
              v-slot="slotItem"
              v-model="documentLinkAccessUser"
              :dataArray="users"
              title="Document Link Default Access User"
              inputType="plain-list-search"
              valueName="value"
              displayName="name"
              width="100%"
              class="attribute-droplist"
              :openAbove="true"
              @input="isDirty = true"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
          </div>
          <div>
            <VasionButton
              id="btnSendTestMessage"
              :classProp="'primary'"
              @vasionButtonClicked="sendTestMessage"
            >
              Send Test Message
            </VasionButton>
          </div>
        </div>
      </div>
    </div>
    <VasionSnackbar
      id="email-settings-snack"
      :showSnackbarBool.sync="showSnackbar"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

const PasswordPlaceholder = '790bbf9a16b84c6bb243'

export default {
  name: 'EmailSettings',
  components: {
    Loading,
  },
  data: function () {
    return {
      documentLinkAccessUser: 0,
      isLoading: false,
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      logoffUserRequestTemplate: 0,
      maxMergeSize: 0,
      promptToBurnAnnotations: false,
      promptForPDFPassword: false,
      sendEmailFrom: '',
      showSnackbar: false,
      showSnackbarBool: false,
      snackbarImage: false,
      snackbarSubTitle: '',
      snackbarTitle: '',
      smtpEnableSSL: true,
      smtpPassword: '',
      smtpPasswordIsSet: false,
      smtpPort: '',
      smtpServer: '',
      smtpUsername: '',
    }
  },
  computed: {
    templates() {
      const templateArray = this.$store.state.common.emailTemplates.map((t) => {
        return {
          name: t.sName,
          value: t.iID,
        }
      })
      templateArray.unshift({name: '', value: 0})
      return templateArray
    },
    users() {
      const userArray = this.$store.state.common.users
      userArray.unshift({name: '', value: 0})
      return userArray
    },
  },
  async created() {
    let settings
    [settings] = await Promise.all([
      this.$store.dispatch('admin/getEmailSettings'),
      this.$store.dispatch('common/getEmailTemplates'),
      this.$store.dispatch('common/getUsers'),
    ])
    this.sendEmailFrom = settings.sendEmailFrom
    this.smtpServer = settings.smtpServer
    this.smtpPort = settings.smtpPort
    this.smtpUsername = settings.smtpUsername
    this.smtpPassword = settings.smtpPasswordIsSet ? PasswordPlaceholder : ''
    this.smtpPasswordIsSet = settings.smtpPasswordIsSet
    this.smtpEnableSSL = settings.smtpEnableSSL
    this.maxMergeSize = settings.maxMergeSize
    this.promptToBurnAnnotations = settings.promptToBurnAnnotations
    this.promptForPDFPassword = settings.promptForPDFPassword
    this.logoffUserRequestTemplate = this.templates.find(x => x.value === settings.logoffUserRequestTemplate)
    this.documentLinkAccessUser = this.users.find(x => x.value === settings.documentLinkAccessUser)
  },
  methods: {
    buildPayload() {
       const payload = {
        sendEmailTechnique: 1,
        sendEmailFrom: this.sendEmailFrom,
        smtpServer: this.smtpServer,
        smtpPort: this.smtpPort,
        smtpUsername: this.smtpUsername,
        smtpPassword: this.smtpPassword === PasswordPlaceholder ? null : this.smtpPassword,
        smtpEnableSSL: this.smtpEnableSSL,
        maxMergeSize: this.maxMergeSize,
        promptToBurnAnnotations: this.promptToBurnAnnotations,
        promptForPDFPassword: this.promptForPDFPassword,
        logoffUserRequestTemplate: this.logoffUserRequestTemplate?.value ? this.logoffUserRequestTemplate.value : 0,
        documentLinkAccessUser: this.documentLinkAccessUser?.value ? this.documentLinkAccessUser.value : 0,
      }

      return payload
    },
    hideSnackbar() { this.showSnackbar = false },
    async saveConfig(showSnackBar = true) {
      if (!this.validateData()) {
        this.showSnackbar = true
        return false
      }

      const payload = this.buildPayload()
      this.isLoading = true
      const result = await this.$store.dispatch('admin/saveEmailSettings', payload)

      this.snackbarSubTitle = result ? 'Settings saved' : 'Error saving data'
      this.snackbarImage = true
      this.isLoading = false
      this.showSnackbar = showSnackBar || !result ? true : false
      return result ? true : false
    },
    passwordInputOnBlur() {
      if (this.smtpPasswordIsSet && this.smtpPassword === '') {
        this.smtpPassword = PasswordPlaceholder
      }
    },
    passwordInputOnFocus() {
      if (this.smtpPassword === PasswordPlaceholder) {
        this.smtpPassword = ''
      }
    },
    async sendTestMessage() {
      if (!await this.saveConfig(false)) {
        return
      }
      this.showSnackbar = false
      this.snackbarSubTitle = 'Test message sent!'
      this.snackbarImage = true
      this.isLoading = true
      const response = await this.$store.dispatch('admin/sendTestEmail')
      if (!response || response.Value !== '') {
        this.snackbarSubTitle = response.Value
        this.snackbarImage = false
      }

      this.isLoading = false
      this.showSnackbar = true
    },
    validateData() {
      this.snackbarSubTitle = ''
      this.snackbarImage = false

      if (!this.sendEmailFrom) {
        this.snackbarSubTitle = 'Please enter the Send Email From.'
        return false
      }

      if (!this.smtpServer) {
        this.snackbarSubTitle = 'Please enter the SMTP Server'
        return false
      }

      if (!this.smtpPort) {
        this.snackbarSubTitle = 'Please enter the SMTP Port'
        return false
      }

      if (!this.smtpUsername) {
        this.snackbarSubTitle = 'Please enter the SMTP Username'
        return false
      }

      if (!this.smtpPassword) {
        this.snackbarSubTitle = 'Please enter the SMTP Password'
        return false
      }

      this.snackbarImage = false
      return true
    },
  },
}
</script>

<style lang="scss" scoped>
.bottom-buffer {
  padding-bottom: 15px;
}

.bottom-buffer-large {
  padding-bottom: 40px;
}

.right-buffer {
  padding-right: 20px;
}

.saveButtonDiv {
  text-align: right;
}

.mainDiv {
  background-color: white;
}

.title-div {
  margin: 20px;
}

.medium-input {
  width: 350px !important;
}
</style>